import axios from 'axios';
const axiosConfig = {
  // baseURL: 'http://localhost:8443/api/v1',
  // baseURL: "https://dabf.services/api/v1",
  // baseURL: "https://server.trustit.digital/api/v1",
  baseURL: "https://xrayserver.trustit1.com.bd/api/v1",
};
const callApi = axios.create(axiosConfig);

export default callApi;
